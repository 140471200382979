// import Banner from "components/Banner";
// import ReasonStatistics from "components/ReasonStatistics";
// import HowWorks from "components/HowWorks";
// import SmeHealthJobs from "components/SmeHealthJobs";
// import HearFromCustomer from "components/HearFromCustomer/HearFromCustomer";

// import { tikTokShop } from "constant/reasonData";
// import { tikTokShopAccordion } from "constant/accordion";
// // import { howWorksDataTikTok } from "constant/howWorksData";

// import bannerImage from "assets/images/tikTokShop/tikTokBanner.png";
// // import bannerImagePhn from "assets/images/amazonSeller/amazonSellerBannerPhn.png";

// const TikTokShop = () => {
//   const bannerData = {
//     title: "",
//     gradientText: "Working Capital For TikTok Shop Sellers",
//     paraGraph: (
//       <span>
//         Get up to US$2M to fund your growth using just your sales on TikTok
//         Shop. No additional income documents. <br />
//         <span className="amazonContentText">
//           {" "}
//           Pay only when you use the funds
//         </span>
//       </span>
//     ),
//     specialText: "FAST | FLEXIBLE | AFFORDABLE",
//     clickingText: (
//       <span>
//         <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span>By
//         clicking this, you will be redirected to TikTok shop seller platform
//       </span>
//     ),
//     clickingTextOfOther: (
//       <span>
//         <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span>By
//         clicking this, you will be redirected to our onboarding form
//       </span>
//     ),
//     bannerImg: bannerImage,
//     button: "Apply Now",
//     link: "https://production.dtpgpnb5pgnbr.amplifyapp.com/"
//   };

//   const payLaterDirectTitle = {
//     title: "Frequently Asked ",
//     subTitle: "Questions"
//   };

//   return (
//     <section className="tikTokShop">
//       <Banner
//         bannerData={bannerData}
//         amazonSellerBannerData={"amazonSellerBannerData"}
//         amazonSellerBannerParaData={"amazonSellerBannerParaData"}
//         amazonSellerBannerImage={"amazonSellerBannerImage"}
//         amazonSellerSpecialText={"amazonSellerSpecialText"}
//         amazonSellerCardHeading={"amazonSellerCardHeading"}
//         amazonSellerClickingText={"amazonSellerClickingText"}
//       />
//       <HearFromCustomer />
//       <ReasonStatistics
//         reasonData={tikTokShop}
//         amazonSellerCardHeading={"amazonSellerCardHeading"}
//         amazonSellerTitleFlex={"amazonSellerTitleFlex"}
//         amazonSellerTitleHeading={"amazonSellerTitleHeading"}
//       />
//       {/* <HowWorks howWorksData={howWorksDataTikTok} /> */}
//       <HowWorks />
//       <SmeHealthJobs
//         accordionData={tikTokShopAccordion}
//         accordionTitle={payLaterDirectTitle}
//         amazonSellerBackground={"amazonSellerBackground"}
//         amazonSellerFaqBottomGap={"amazonSellerFaqBottomGap"}
//       />
//     </section>
//   );
// };

// export default TikTokShop;

import React, { useState, useRef, useEffect } from "react";
import Banner from "components/Banner";
import ReasonStatistics from "components/ReasonStatistics";
import HowWorks from "components/HowWorks";
import SmeHealthJobs from "components/SmeHealthJobs";
import HearFromCustomer from "components/HearFromCustomer/HearFromCustomer";

import featuresSvg from "assets/images/amazonSeller/features.svg";
import blackFeaturesSvg from "assets/images/amazonSeller/blackfeatureIcon.svg";
import howItWorksSvg from "assets/images/amazonSeller/howItWorks.svg";
import whiteHowItWorksSvg from "assets/images/amazonSeller/whiteHowItsWorkIcon.svg";
import faqSvg from "assets/images/amazonSeller/faq.svg";
import whiteFaqSvg from "assets/images/amazonSeller/whiteFAQIcon.svg";
import testimonialsSvg from "assets/images/amazonSeller/testimonials.svg";
import whiteTestimonialsSvg from "assets/images/amazonSeller/whiteTestimonial.svg";

import { tikTokShop } from "constant/reasonData";
import { tikTokShopAccordion } from "constant/accordion";

import bannerImage from "assets/images/tikTokShop/tikTokBanner.png";

import "containers/TikTokShop/tikTokShop.scss";
import useScreenMobile from "hooks/useScreenMobile";

const navButtons = [
  {
    name: "Testimonials",
    icon: testimonialsSvg,
    whiteIcon: whiteTestimonialsSvg
  },
  { name: "Features", icon: blackFeaturesSvg, whiteIcon: featuresSvg },
  { name: "How it Works", icon: howItWorksSvg, whiteIcon: whiteHowItWorksSvg },
  { name: "FAQ", icon: faqSvg, whiteIcon: whiteFaqSvg }
];

const TikTokShop = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [sticky, setSticky] = useState(false);

  const sectionTabsRefs = useRef(null);
  const sectionsRef = useRef([]);

  const isTab = useScreenMobile({ size: 769 });
  const isMobile = useScreenMobile({ size: 569 });

  const bannerData = {
    title: "",
    gradientText: "Working Capital For TikTok Shop Sellers",
    paraGraph: (
      <span>
        Get up to US$2M to fund your growth using just your sales on TikTok
        Shop. No additional income documents. <br />
        <span className="amazonContentText">
          Pay only when you use the funds
        </span>
      </span>
    ),
    specialText: "FAST | FLEXIBLE | AFFORDABLE",
    clickingText: (
      <span>
        <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span>By
        clicking this, you will be redirected to TikTok shop seller platform
      </span>
    ),
    clickingTextOfOther: (
      <span>
        <span style={{ color: "#9B3581", paddingRight: "5px" }}>*</span>By
        clicking this, you will be redirected to our onboarding form
      </span>
    ),
    bannerImg: bannerImage,
    button: "Apply Now",
    link: "https://production.dtpgpnb5pgnbr.amplifyapp.com/"
  };

  const payLaterDirectTitle = {
    title: "Frequently Asked ",
    subTitle: "Questions"
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 722) {
        setSticky(true);
      }
      if (scrollTop <= 622) {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionsRef.current.findIndex(
              (section) => section.id === entry.target.id
            );
            setActiveTab(index);
          }
        });
      },
      { threshold: [0.5, 1] }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (sectionsRef.current[index]) {
      // const navHeight = sectionTabsRefs.current?.offsetHeight;
      // sectionsRef.current[index].style.scrollMarginTop = `${navHeight}px`;
      sectionsRef.current[index].style.scrollMarginTop = "70px";

      sectionsRef.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  };

  return (
    <section className="tikTokShop">
      <Banner
        bannerData={bannerData}
        amazonSellerBannerData={"amazonSellerBannerData"}
        amazonSellerBannerParaData={"amazonSellerBannerParaData"}
        amazonSellerBannerImage={"amazonSellerBannerImage"}
        amazonSellerSpecialText={"amazonSellerSpecialText"}
        amazonSellerCardHeading={"amazonSellerCardHeading"}
        amazonSellerClickingText={"amazonSellerClickingText"}
      />
      <div className="sectionTabsBox">
        {(!isMobile || !isTab) && (
          <div
            className={`sectionsNav ${sticky ? "sectionNav sticky" : ""}`}
            ref={sectionTabsRefs}
          >
            <div className="buttonsBox">
              {navButtons.map((item, index) => (
                <button
                  className={`button ${activeTab === index ? "active" : ""}`}
                  key={index}
                  onClick={() => handleTabClick(index)}
                >
                  <img
                    src={activeTab === index ? item.whiteIcon : item.icon}
                    alt=""
                  />
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        )}

        <section
          style={{ paddingTop: "70px" }}
          key={0}
          id={"section0"}
          ref={(el) => (sectionsRef.current[0] = el)}
        >
          <HearFromCustomer />
        </section>
        <section
          key={1}
          id={"section1"}
          ref={(el) => (sectionsRef.current[1] = el)}
        >
          <ReasonStatistics
            reasonData={tikTokShop}
            amazonSellerCardHeading={"amazonSellerCardHeading"}
            amazonSellerTitleFlex={"amazonSellerTitleFlex"}
            amazonSellerTitleHeading={"amazonSellerTitleHeading"}
          />
        </section>
        <section
          key={2}
          id={"section2"}
          ref={(el) => (sectionsRef.current[2] = el)}
        >
          <HowWorks />
        </section>
        <section
          key={3}
          id={"section3"}
          ref={(el) => (sectionsRef.current[3] = el)}
        >
          <SmeHealthJobs
            accordionData={tikTokShopAccordion}
            accordionTitle={payLaterDirectTitle}
            amazonSellerBackground={"amazonSellerBackground"}
            amazonSellerFaqBottomGap={"amazonSellerFaqBottomGap"}
          />
        </section>
      </div>
    </section>
  );
};

export default TikTokShop;
